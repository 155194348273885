import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../img/LogoWhite.svg";

const NavBar = ({ user, time, alarm, state }) => {
	const orderDueStyle = () => {
		if (alarm === true) {
			return "Order-Due";
		} else {
			return "bg-dark";
		}
	};

	return (
		<React.Fragment>
			<nav className={"navbar navbar-expand-lg navbar-dark Nav-Bar-Style " + orderDueStyle()}>
				<img src={logo} alt="Logo" style={{ width: "270px", marginLeft: "50px" }} />

				<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
					<span className="navbar-toggler-icon"></span>
				</button>

				<div className="collapse navbar-collapse" id="navbarText">
					<ul className="navbar-nav mr-auto"></ul>
					{user.user_id && (
						<React.Fragment>
							<div className="menuMidText">
								{alarm && (
									<React.Fragment>
										<h1 style={{ fontSize: "80px", fontWeight: "bold" }}>ORDER DUE</h1>
									</React.Fragment>
								)}
							</div>
							<div className="menuRightText">
								<h1>
									{time.toLocaleTimeString(undefined, {
										hour: "2-digit",
										minute: "2-digit",
										second: "2-digit",
									})}
								</h1>
								<h5>
									{time.toLocaleDateString(undefined, {
										day: "numeric",
										month: "numeric",
										year: "numeric",
									})}
								</h5>
							</div>
						</React.Fragment>
					)}
					<span>
						<div className="collapse navbar-collapse" id="navbarText">
							<ul className="navbar-nav mr-auto">
								{!user.user_id && (
									<React.Fragment>
										<li className="nav-item">
											<NavLink className="nav-link" to="/log-in">
												Log In
											</NavLink>
										</li>
									</React.Fragment>
								)}

								{/*{user.first_name && (
                  <React.Fragment>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/">
                        {user.first_name}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/log-out">
                        Log Out
                      </NavLink>
                    </li>
                </React.Fragment>
                )}*/}
							</ul>
						</div>
					</span>
				</div>
			</nav>

			{state ? (
				<React.Fragment></React.Fragment>
			) : (
				<div className="Internet-Down-Error">
					<h4>
						<span className="badge badge-danger py-2 px-5">
							/ / / / / / / / / / / / / / / / <i className="fas fa-exclamation-triangle"> </i> <i className="fas fa-wifi"></i>
						</span>{" "}
						<strong> NO INTERNET DETECTED, CHECK YOUR CONNECTION. </strong>{" "}
						<span className="badge badge-danger py-2 px-5">
							<i className="fas fa-wifi"></i> <i className="fas fa-exclamation-triangle"> </i> / / / / / / / / / / / / / / / /
						</span>{" "}
					</h4>
				</div>
			)}
		</React.Fragment>
	);
};

export default NavBar;
