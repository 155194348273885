import React from "react";
import OrderRow from "./orderRow";

const OrdersList = ({ orders, selectedOrderFunc, selectedOrderId }) => {
  return (
    <div className="list-group ">
      <button type="button" disabled={true} className="list-group-item list-group-item-action list-group-item-dark active">
        <div className="row d-flex align-items-center">
          <div className="col-sm-3 ">Order#</div>
          <div className="col-sm-4 ">Time to Pick Up</div>
          <div className="col-sm-2 ">Sections</div>
          <div className="col-sm-3 ">Order Status</div>
        </div>
      </button>

      <div className="OrdersList">
        {orders && orders.map((order, index) => <OrderRow key={index} order={order} selectedOrderFunc={selectedOrderFunc} selectedOrderId={selectedOrderId}></OrderRow>)}
      </div>
    </div>
  );
};

export default OrdersList;
