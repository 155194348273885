import React, { useEffect } from "react";

const OrderRow = ({ order, selectedOrderFunc, selectedOrderId }) => {
  const [orderRowStyle, setOrderRowStyle] = React.useState({});

  useEffect(() => {
    function orderStyleCalc(order) {
      let res = {};
      res.pizzaSection = sectionTest("pizzaSection", order);
      res.grillSection = sectionTest("grillSection", order);
      res.burgerSection = sectionTest("burgerSection", order);
      setOrderRowStyle(res);
    }
    orderStyleCalc(order);
  }, [order]);

  function orderStatStyle(order) {
    if (order.status === "processing" && order.totalMinutesToPickUp >= 30) {
      return "list-group-item-secondary";
    } else if (order.status === "processing" && order.totalMinutesToPickUp < 30 && order.totalMinutesToPickUp >= 0) {
      return "list-group-item-warning";
    } else if (order.status === "processing" && order.totalMinutesToPickUp < 0) {
      return "list-group-item-danger";
    } else if (order.status === "preparing-pending") {
      return "list-group-item-success";
    }
  }

  function orderActiveStyle(id, selectedOrderId) {
    if (id === selectedOrderId) {
      return " active";
    } else {
      return "";
    }
  }

  function sectionTest(section, order) {
    if (order[section] !== undefined) {
      if (order[section].length === 0) {
        return "opacity-3";
      } else {
        return "";
      }
    }
  }

  const handleClick = (id) => {
    selectedOrderFunc(id);
  };

  return (
    <button
      type="button"
      className={"list-group-item list-group-item-action py-0 " + orderStatStyle(order) + orderActiveStyle(order.id, selectedOrderId)}
      onClick={() => handleClick(order.id)}
    >
      <div className="row d-flex align-items-center">
        <div className="col-sm-3">
          <h2>#{order.id}</h2>
        </div>
        <div className="col-sm-4">
          <h5>
            {order.status === "processing" && order.daysToPickUp !== 0 && `${order.daysToPickUp} Days ${order.hoursToPickUp} Hrs`}
            {order.status === "processing" && order.daysToPickUp === 0 && order.hoursToPickUp !== 0 && `${order.hoursToPickUp} Hrs ${order.minutesToPickUp} Mins`}
            {order.status === "processing" && order.daysToPickUp === 0 && order.hoursToPickUp === 0 && `${order.minutesToPickUp} Mins`}
            {order.status === "preparing-pending" && `Done!`}
          </h5>
        </div>
        <div className="col-sm-2">
          <div className="row d-flex align-items-center">
            <div className={"col-sm-4 d-flex justify-content-center py-3 bg-dark text-light " + orderRowStyle.pizzaSection}>P</div>
            <div className={"col-sm-4 d-flex justify-content-center py-3 bg-dark text-light " + orderRowStyle.grillSection}>G</div>
            <div className={"col-sm-4 d-flex justify-content-center py-3 bg-dark text-light " + orderRowStyle.burgerSection}>B</div>
          </div>
        </div>
        <div className="col-sm-3">
          <h5>{order.FormatedStatus}</h5>
        </div>
      </div>
    </button>
  );
};

export default OrderRow;
