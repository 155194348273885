import React, { useEffect, useRef } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { UrlStart } from "./config/config";
import jwtDecode from "jwt-decode";
import NavBar from "./components/navBar";
import LogIn from "./components/logIn";
import LogOut from "./components/logOut";
import Home from "./components/home";
import NotFound from "./components/notFound";
import CheckOnline from "./services/checkOnline";
import "react-toastify/dist/ReactToastify.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
//import logo from "./logo.svg";
import "./App.css";
import "./css/all.css";

function App() {
	const [user, setUser] = React.useState([]);
	const [time, setTime] = React.useState(new Date());
	const [state, setState] = React.useState(true);
	const [goneOfline, setGoneOfline] = React.useState(false);
	let alarm = useRef(false);

	const stateRefTime = useRef();
	stateRefTime.current = time;

	function handleAlarm(value) {
		alarm.current = value;
	}

	useEffect(() => {
		try {
			const jwt = localStorage.getItem("auth-token");
			const jwtDecoded = jwtDecode(jwt);
			setUser(jwtDecoded);
		} catch (error) {}

		const interval = setInterval(() => {
			setTime(new Date());
			if (state === false) {
				setGoneOfline(true);
			}
			if (state === true && goneOfline === true) {
				setGoneOfline(false);
				window.location.reload();
			}
		}, 1000);

		return () => clearInterval(interval);
	}, [goneOfline, state]);

	return (
		<React.Fragment>
			<ToastContainer />
			<NavBar user={user} time={time} alarm={alarm.current} state={state} />
			<div className="container-fluid">
				<Switch>
					<Route path={`${UrlStart}/log-in`} component={LogIn} />
					<Route path={`${UrlStart}/log-out`} component={LogOut} />
					<Route path={`${UrlStart}/not-found`} component={NotFound} />
					{!user.user_id && (
						<Route path={`${UrlStart}/`} exact>
							<Redirect to={`${UrlStart}/log-in`} />
						</Route>
					)}
					<Route path={`${UrlStart}/`} exact render={(props) => <Home {...props} user={user} alarmStatus={handleAlarm} state={state} goneOfline={goneOfline} setGoneOfline={setGoneOfline}></Home>} />
					<Redirect to={`${UrlStart}/log-in`} />
				</Switch>
			</div>
			<CheckOnline setState={setState}></CheckOnline>
		</React.Fragment>
	);
}

export default App;
