import React, { useRef } from "react";
import ItemsList from "../components/itemsList";
import Loader from "react-loader-spinner";
import { useReactToPrint } from "react-to-print";
import logo from "../img/Logo.svg";

const SingleOrderDisp = ({ selectedOrder, acceptOrderFunc }) => {
	const [showWait, setShowWait] = React.useState(false);
	const componentRef = useRef();

	function orderStatStyle(selectedOrder) {
		if (selectedOrder.status === "processing" && selectedOrder.totalMinutesToPickUp >= 30) {
			return "card border-secondary mb-3";
		} else if (selectedOrder.status === "processing" && selectedOrder.totalMinutesToPickUp < 30 && selectedOrder.totalMinutesToPickUp >= 0) {
			return "card border-warning mb-3";
		} else if (selectedOrder.status === "processing" && selectedOrder.totalMinutesToPickUp < 0) {
			return "card border-danger mb-3";
		} else if (selectedOrder.status === "preparing-pending") {
			return "card border-success mb-3";
		}
	}

	function orderCustNoteStyle(customer_note) {
		if (customer_note !== "" && customer_note !== undefined) {
			return "card border-danger ";
		} else {
			return "card border-dark ";
		}
	}

	function mealDealStyle(mealDealCount) {
		if (mealDealCount > 0) {
			return "card bg-danger text-white";
		} else {
			return "card bg-light text-dark";
		}
	}

	const handleAcceptClick = (id) => {
		acceptOrderFunc(id);
		handlePrint();
		setShowWait(true);
		setTimeout(function () {
			setShowWait(false);
		}, 6000);
	};

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<React.Fragment>
			<div className="row ">
				<div className="col-md-3">
					<div className="row">
						<div className="col-sm-6">
							<div className="card text-white bg-secondary mb-3">
								<div className="card-header py-1">Order #</div>
								<div className="card-body py-1">
									<h5 className="card-title my-0">
										#{(selectedOrder.id === undefined || selectedOrder.id === "") && ` --`}
										{selectedOrder.id !== undefined && `${selectedOrder.id}`}
									</h5>
								</div>
							</div>
						</div>
						<div className="col-sm-6">
							<div className="card border-secondary mb-3">
								<div className="card-header py-1">Total ($)</div>
								<div className="card-body py-1">
									<h5 className="card-title my-0">
										${(selectedOrder.total === undefined || selectedOrder.total === "") && ` --`}
										{selectedOrder.total !== undefined && `${selectedOrder.total}`}
									</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-5">
					<div className="row">
						<div className="col-sm-7">
							<div className="card border-secondary mb-3">
								<div className="card-header py-1">Pick Up Time</div>
								<div className="card-body py-1">
									<h5 className="card-title my-0">
										{(selectedOrder.formatedOrderDate === undefined || selectedOrder.formatedOrderDate === "") && ` --`}
										{selectedOrder.formatedOrderDate !== undefined && `${selectedOrder.formatedOrderDate}`}
									</h5>
								</div>
							</div>
						</div>

						<div className="col-sm-5">
							<div className={"card border-secondary " + orderStatStyle(selectedOrder)}>
								<div className="card-header py-1">Order Status</div>
								<div className="card-body py-1">
									<h5 className="card-title my-0">
										{(selectedOrder.FormatedStatus === undefined || selectedOrder.FormatedStatus === "") && ` --`}
										{selectedOrder.FormatedStatus !== undefined && `${selectedOrder.FormatedStatus}`}
									</h5>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-4">
					<div className="row">
						<div className="col-sm-7">
							{selectedOrder.status === "processing" && showWait === true && (
								<React.Fragment>
									<button type="button" className="btn btn-success  btn-lg btn-block py-3" disabled>
										<Loader className="py-0" type="ThreeDots" color="white" height={20} width={100} timeout={10000} />
									</button>
								</React.Fragment>
							)}
							{selectedOrder.status === "processing" && showWait === false && (
								<button type="button" className="btn btn-success  btn-lg btn-block py-3" onClick={() => handleAcceptClick(selectedOrder.id)}>
									<i className="fas fa-vote-yea"></i> Accept Order
								</button>
							)}
							{selectedOrder.status !== "processing" && (
								<button type="button" className="btn btn-success  btn-lg btn-block py-3" disabled>
									<i className="fas fa-vote-yea"></i> Accept Order
								</button>
							)}
						</div>
						<div className="col-sm-5">
							<button type="button" onClick={handlePrint} className="btn btn-outline-secondary  btn-lg btn-block py-3">
								<i className="fas fa-print"></i> Print
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-3">
					<div className="card bg-light mb-3">
						<div className="card-header py-1">First Name</div>
						<div className="card-body py-1">
							<p className="card-text my-0">
								{(selectedOrder.first_name === undefined || selectedOrder.first_name === "") && ` --`}
								{selectedOrder.first_name !== undefined && `${selectedOrder.first_name}`}
							</p>
						</div>
					</div>
					<div className="card bg-light mb-3">
						<div className="card-header py-1">Email</div>
						<div className="card-body py-1">
							<p className="card-text my-0">
								{(selectedOrder.email === undefined || selectedOrder.email === "") && ` --`}
								{selectedOrder.email !== undefined && `${selectedOrder.email}`}
							</p>
						</div>
					</div>
				</div>
				<div className="col-md-3">
					<div className="card bg-light mb-3">
						<div className="card-header py-1">Last Name</div>
						<div className="card-body py-1">
							<p className="card-text my-0">
								{(selectedOrder.last_name === undefined || selectedOrder.last_name === "") && ` --`}
								{selectedOrder.last_name !== undefined && `${selectedOrder.last_name}`}
							</p>
						</div>
					</div>
					<div className="card bg-light mb-3">
						<div className="card-header py-1">Phone</div>
						<div className="card-body py-1">
							<p className="card-text my-0">
								{(selectedOrder.phone === undefined || selectedOrder.phone === "") && ` --`}
								{selectedOrder.phone !== undefined && `${selectedOrder.phone}`}
							</p>
						</div>
					</div>
				</div>
				<div className="col-md-2">
					<div className={"mb-3 " + mealDealStyle(selectedOrder.mealBotOpt)}>
						<div className="card-header py-1">600ml Meal Deal</div>
						<div className="card-body py-1">
							<p className="card-text my-0">
								{(selectedOrder.mealBotOpt === undefined || selectedOrder.mealBotOpt === 0) && ` --`}
								{selectedOrder.mealBotOpt !== 0 && selectedOrder.mealBotOpt !== undefined && `${selectedOrder.mealBotOpt}`}
							</p>
						</div>
					</div>
					<div className={"mb-3 " + mealDealStyle(selectedOrder.mealCanOpt)}>
						<div className="card-header py-1">Can Meal Deal</div>
						<div className="card-body py-1">
							<p className="card-text my-0">
								{(selectedOrder.mealCanOpt === undefined || selectedOrder.mealCanOpt === 0) && ` --`}
								{selectedOrder.mealCanOpt !== 0 && selectedOrder.mealCanOpt !== undefined && `${selectedOrder.mealCanOpt}`}
							</p>
						</div>
					</div>
				</div>
				<div className="col-md-4  d-flex align-items-stretch">
					<div className={"mb-3 flex-fill " + orderCustNoteStyle(selectedOrder.customer_note)}>
						<div className="card-header py-1">Customer Notes</div>
						<div className="card-body text-danger py-1">
							<p className="card-text my-0 font-weight-bold">
								{(selectedOrder.customer_note === undefined || selectedOrder.customer_note === "") && ` `}
								{selectedOrder.customer_note !== undefined && `${selectedOrder.customer_note}`}
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-4">
					<div className="card border-dark mb-3">
						<div className="card-header bg-dark text-white py-1">Pizza Section</div>
						<div className="card-body py-0 pr-0">
							<div className="card-text py-1 pr-3 OrdersDetailsText">
								{(selectedOrder.pizzaSection === undefined || selectedOrder.pizzaSection === "") && ` --`}
								{selectedOrder.pizzaSection !== undefined &&
									selectedOrder.pizzaSection &&
									selectedOrder.pizzaSection.map((item, index) => (
										<ItemsList key={index} item={item} maxWidth="350px" textColour="text-danger" bgColour="bg-light" badgeDark="badge-dark" badgeRed="badge-danger"></ItemsList>
									))}
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-4">
					<div className="card border-dark mb-3">
						<div className="card-header bg-dark text-white py-1">Grill Section</div>
						<div className="card-body py-0 pr-0">
							<div className="card-text py-1 pr-3 OrdersDetailsText">
								{(selectedOrder.grillSection === undefined || selectedOrder.grillSection === "") && ` --`}
								{selectedOrder.grillSection !== undefined &&
									selectedOrder.grillSection &&
									selectedOrder.grillSection.map((item, index) => (
										<ItemsList key={index} item={item} maxWidth="350px" textColour="text-danger" bgColour="bg-light" badgeDark="badge-dark" badgeRed="badge-danger"></ItemsList>
									))}
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-4">
					<div className="card border-dark mb-3">
						<div className="card-header bg-dark text-white py-1">Burger Section</div>
						<div className="card-body py-0 pr-0">
							<div className="card-text py-1 pr-3 OrdersDetailsText">
								{(selectedOrder.burgerSection === undefined || selectedOrder.burgerSection === "") && ` --`}
								{selectedOrder.burgerSection !== undefined &&
									selectedOrder.burgerSection &&
									selectedOrder.burgerSection.map((item, index) => (
										<ItemsList key={index} item={item} maxWidth="350px" textColour="text-danger" bgColour="bg-light" badgeDark="badge-dark" badgeRed="badge-danger"></ItemsList>
									))}
							</div>
						</div>
					</div>
				</div>
			</div>
			<React.Fragment>
				<div style={{ display: "none" }}>
					<div ref={componentRef}>
						<div className="Invoice-Container">
							{selectedOrder.id !== undefined && (
								<React.Fragment>
									<img src={logo} alt="Logo" style={{ width: "70mm", marginLeft: "8mm", marginBottom: "5mm" }} />
									<h3>TAX INVOICE</h3>
									<p>
										PIZZA PALACE GRILL AND PIDE PTY LTD
										<br />
										ABN: 26 639 266 943
										<br />
										P: (07) 3800 0440
										<br />
										A: SHOP 1C/15-23 Eastern Rd,
										<br />
										Browns Plains QLD 4118
										<br />
										W: www.pizzapalace.com.au
									</p>
									<h5>
										Order Number: <strong>#{selectedOrder.id}</strong>
									</h5>
									<p>
										Order Pick Up Date: {selectedOrder.formatedOrderDate}
										<br />
										For: {selectedOrder.first_name} {selectedOrder.last_name}
									</p>

									{selectedOrder.mealBotOpt > 0 && (
										<h5>
											<div className="badge badge-pill badge-light text-danger border border-danger">{selectedOrder.mealBotOpt}x 600ml Drink / Chips Meal Deals</div>
										</h5>
									)}
									{selectedOrder.mealCanOpt > 0 && (
										<h5>
											<div className="badge badge-pill badge-light text-danger border border-danger">{selectedOrder.mealCanOpt}x Soft Drink Can / Chips Meal Deals</div>
										</h5>
									)}
									{selectedOrder.customer_note !== "" && selectedOrder.customer_note !== undefined && (
										<React.Fragment>
											<h5>
												<div style={{ overflowWrap: "break-word" }} className="badge badge-pill badge-light text-danger border border-danger">
													Customer Note:{" "}
												</div>
											</h5>
											<p>{selectedOrder.customer_note}</p>
										</React.Fragment>
									)}
									{selectedOrder.pizzaSection !== undefined &&
										selectedOrder.pizzaSection.map((item, index) => (
											<div key={index}>
												{item.quantity}x {item.name} <span className="float-right">${item.total}</span>
											</div>
										))}
									{selectedOrder.grillSection !== undefined &&
										selectedOrder.grillSection.map((item, index) => (
											<div key={index}>
												{item.quantity}x {item.name} <span className="float-right">${item.total}</span>
											</div>
										))}
									{selectedOrder.burgerSection !== undefined &&
										selectedOrder.burgerSection.map((item, index) => (
											<div key={index}>
												{item.quantity}x {item.name} <span className="float-right">${item.total}</span>
											</div>
										))}
									<h5>
										<span className="float-right">GST ${(selectedOrder.total * 0.1).toFixed(2)}</span>
										<br />
										<span className="float-right">TOTAL (inc GST) ${selectedOrder.total}</span>
									</h5>
									<p>
										<br />
									</p>
								</React.Fragment>
							)}

							{selectedOrder.pizzaSectionSequance > 0 && (
								<React.Fragment>
									<div className="pagebreak"> </div>
									<h2>PIZZA SECTION</h2>
									<h3>
										Order# {selectedOrder.id} --- [{selectedOrder.pizzaSectionSequance}/{selectedOrder.totalSectionCount}]
									</h3>
									{(selectedOrder.pizzaSection === undefined || selectedOrder.pizzaSection === "") && ` --`}
									{selectedOrder.pizzaSection !== undefined &&
										selectedOrder.pizzaSection &&
										selectedOrder.pizzaSection.map((item, index) => (
											<ItemsList
												key={index}
												item={item}
												maxWidth="100mm"
												textColour="text-dark"
												bgColour="bg-white"
												badgeDark="badge-light text-dark border border-dark"
												badgeRed="badge-light text-danger border border-danger"
											></ItemsList>
										))}
								</React.Fragment>
							)}

							{selectedOrder.grillSectionSequance > 0 && (
								<React.Fragment>
									<div className="pagebreak"> </div>
									<h2>GRILL SECTION</h2>
									<h3>
										Order# {selectedOrder.id} --- [{selectedOrder.grillSectionSequance}/{selectedOrder.totalSectionCount}]
									</h3>
									{(selectedOrder.grillSection === undefined || selectedOrder.grillSection === "") && ` --`}
									{selectedOrder.grillSection !== undefined &&
										selectedOrder.grillSection &&
										selectedOrder.grillSection.map((item, index) => (
											<ItemsList
												key={index}
												item={item}
												maxWidth="100mm"
												textColour="text-dark"
												bgColour="bg-white"
												badgeDark="badge-light text-dark border border-dark"
												badgeRed="badge-light text-danger border border-danger"
											></ItemsList>
										))}
								</React.Fragment>
							)}

							{selectedOrder.burgerSectionSequance > 0 && (
								<React.Fragment>
									<div className="pagebreak"> </div>
									<h2>BURGER SECTION</h2>
									<h3>
										Order# {selectedOrder.id} --- [{selectedOrder.burgerSectionSequance}/{selectedOrder.totalSectionCount}]
									</h3>
									{(selectedOrder.burgerSection === undefined || selectedOrder.burgerSection === "") && ` --`}
									{selectedOrder.burgerSection !== undefined &&
										selectedOrder.burgerSection &&
										selectedOrder.burgerSection.map((item, index) => (
											<ItemsList
												key={index}
												item={item}
												maxWidth="100mm"
												textColour="text-dark"
												bgColour="bg-white"
												badgeDark="badge-light text-dark border border-dark"
												badgeRed="badge-light text-danger border border-danger"
											></ItemsList>
										))}
								</React.Fragment>
							)}
						</div>
					</div>
				</div>
			</React.Fragment>
		</React.Fragment>
	);
};

export default SingleOrderDisp;
